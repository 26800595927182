import React,{useEffect, useState} from 'react'
import { NavLink} from 'react-router-dom';
import { MdMailOutline } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";


const About = () => {
  const mailIcon = <MdMailOutline size={45} color="#ffffff" />
  const mailIcon2 = <MdMailOutline size={25} color="#fffff0" />
  const locationIcon = <FaLocationDot size={20}  color='fffff0'/>
  const phoneIcon = <FaPhoneAlt size={20}  color='fffff0'/>
  const[currentScroll,setCurrentScroll] = useState(false)
  const industryImg = process.env.PUBLIC_URL + 'images/industry.jpg'
  const logoImage = process.env.PUBLIC_URL + 'images/logoWhite.png'

  useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top after component mounts



    const scrollYbottom=()=>{
      
        if(window.scrollY>1000){
        setCurrentScroll(true) 
      }
   

    }

    window.addEventListener('scroll',scrollYbottom)

    return()=>{
      window.removeEventListener('scroll',scrollYbottom)
    }


  }, []);

  const images = [process.env.PUBLIC_URL + 'images/design4.jpg',
                  process.env.PUBLIC_URL + 'images/design3.jpg']
  return (
    <>
    <div>
      
    <div className='w-[100%] h-28'></div>
    
    <div className='lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] lg:flex lg:flex-row md:flex md:flex-row sm:flex sm:flex-col xs:flex xs:flex-col xs: pt-4'>
    
   
   
    <div className='lg:w-[45%] md:w-[45%] sm:w-[90%] xs:w-[90%] lg:text-base md:text-lg text-justify font-thin text-gray-400 pl-12'>

    <div className='text-4xl font-bold pt-1 text-gray-600'>About Us</div><br/>

    <p style={{ lineHeight: '1.6' }}>
      &nbsp;&nbsp;Established in 2016, BLR Safety Solutions specializes in distributing Industrial Safety Products, gaining a 
      reputable name in the industry for our high-quality range, including Safety Helmets, Safety Goggles, and Safety 
      Respirators,personal protective equipment (PPE), fall protection systems, respiratory protection, fire safety equipment, 
      and more.     
      <br/>
      <br/>
      &nbsp;&nbsp;These products boast high tensile strength, resistance to wear and tear, precise fitting, seamless finish, comfort, 
      lightweight design, and comprehensive safety for workers in various settings such as traffic, construction sites, 
      chemical plants, oil refineries, welding industries, and pesticide plants. All our products adhere to the highest 
      industry standards and are certified by reputable organizations such as OSHA and EN.
      <br/>
      <br/>
      &nbsp;&nbsp;Our extensive infrastructural facility spans a large area and is equipped with state-of-the-art amenities. 
      To handle bulk orders efficiently, we have a sophisticated warehouse facility. We source raw materials from 
      reliable vendors to ensure the use of high-grade products. With a dedicated quality control team to ensure , 
      we maintain the excellence of our products. Innovative packaging techniques are employed to guarantee product 
      safety during long-distance shipments. Our transportation facility ensures timely deliveries. A diligent team 
      of professionals is committed to the firm's growth.
      <br/>
      <br/>
      &nbsp;&nbsp;BLR Safety Solutions is a technology-driven safety supply company dedicated to delivering innovative solutions 
      for the construction and industrial sectors. Our team of experts collaborates closely with clients to understand 
      their unique safety requirements, offering tailored solutions that are not only effective but also cost-efficient.
       At BLR Safety Solutions, our commitment is to provide clients with superior service and support. We aim to surpass 
       expectations by delivering exceptional quality and value. Contact us today to discover how we can contribute to 
       maintaining a safe and compliant workplace for you.
       <br/>
       <br/>
 </p> 
       <div className='text-3xl text-gray-400'>Company Overview:</div><br/>
       <p  style={{ lineHeight: '1.6' }}>
          &nbsp;&nbsp;Offering a comprehensive one-stop solution for the safety requirements of workers in 
          industrial settings, we take pride in being suppliers for various reputable companies.
          Our commitment ensures that customers receive authentic equipment at competitive prices, 
          accompanied by an after-sales service that stands on par with the best in the industry.   
         </p>

      <div className='w-[100%]  pt-8 '>
      <img src={images[0]} alt='image2'/> 
      </div>     

      </div> 
      
    
    <div  className='lg:w-[45%] md:w-[45%] sm:w-[90%] xs:w-[90%]'>        
          
                           
          
          
              <div className='lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] lg:text-base md:text-lg  font-thin text-gray-400 pl-12 lg:pt-2 md:pt-4 sm:pt-8 xs:pt-12 space-y-8'>
              <div className='text-4xl text-gray-600'>Why Choose Us?</div>
              <p className='text-justify' style={{ lineHeight: '1.6' }}>
              &nbsp;&nbsp;We stand out among industry peers for delivering top-notch products to our clients. Our
              advanced infrastructure is well-equipped to manage large-scale orders, supported by an 
              expansive transportation facility ensuring timely deliveries. Employing eco-friendly and 
              efficient packaging methods, we take pride in our commitment to environmental responsibility.
              Backed by a team of experts, we have earned a reputation for:<br/>
              <br/>
              </p>
              </div>

              <div className='w-[100%] pl-12'>
              <img src={images[1]} alt='image2'/>  
              </div> 
          
            <div className='lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] lg:text-base md:text-lg  font-thin text-gray-400 pl-12'> 
              
              <list  className='lg:w-[50%] md:w-[40%] sm:w-[100%] xs:w-[100%]   font-thin text-gray-400 pl-12'style={{ lineHeight: '1.6' }}>
                <li>Quality-Approved Products </li>
                <li>Diligent Professionals </li>
                <li>Comprehensive Product Range </li>
                <li>Systematic Warehouse Management </li>
                <li>Extensive Transportation Network </li>
                <li>Cost-Effective Products </li>
                <li>Adherence to Ethical Business Practices</li>
                <br/>
                <li>Our Dedicated Team:</li>   
                <div className=' text-justify'>
                At the heart of our success is a team of diligent and committed professionals who have not only garnered a 
                recognized clientele but also earned numerous accolades. Our team members, comprising experienced, skilled, 
                dedicated, educated, and well-behaved individuals, relentlessly strive for success. Working in close coordination, 
                they maintain discipline and contribute to our achievements. To enhance their knowledge base, we regularly organize 
                training sessions and seminars. Our team encompasses administrators, engineers, designers, technicians, quality 
                controllers, and transportation experts.
                </div> 
                <br/>
                <li>Commitment to Quality: </li>
                <div className='text-justify'>
                Our industry's primary objective is to provide products that meet the highest quality standards and comply with 
                industry norms. Stringent quality control parameters are in place to ensure the flawless nature of our product 
                range. We source raw materials from a reputable vendor base, scrutinizing their track records based on clientele,
                logistics, and financial status. Clients appreciate our products for their perfect surface finish, resistance to
                wear and tear, non-corrosive nature, precise fitting, exceptional comfort, and lightweight design.
                </div>
                </list>    
            </div>
            <div className='lg:w-[100%] md:w-[100%] sm:w-72 xs:w-72 h-0.5 bg-gray-400 mt-16 ml-8'></div>
      
     
            </div>
        </div>
        
        <div className='absolute flex flex-row w-full lg:h-40 md:h-56 xs:h-36 lg:mt-4 md:mt-0 sm:mt-8 xs:mt-8 bg-[#005E9c]'>
            
            
            <NavLink to='/contact' className='lg:px-5 md:px-5' style={({ isActive }) => ({color: isActive? "#005E9C": "#00171f"})}>  
            <div className={`lg:pt-16 md:pt-16 sm:pt-14 xs:pt-14 text-white transition-transform duration-500 inline-block ${currentScroll ? 'lg:translate-x-full md:translate-x-1/2 sm:translate-x-1/4 xs:translate-x-1/4' : 'translate-x-0' } absolute transition-opacity duration-1000 ${currentScroll ? 'opacity-100' : 'opacity-0'}`} >
                
                    
                      <div className='flex flex-row'>         
                      <div className='lg:px-2 lg:pt-1 md:px-2 sm:px-1 xs:px-1'>{mailIcon}</div>
                      <div className='lg:text-5xl md:text-5xl sm:text-2xl xs:text-2xl lg:pt-0 md:pt-0 sm:pt-2 xs:pt-2'>Get a Quote Now
                      </div>
                      </div>  
                            
                          
              </div>
              </NavLink> 
              


    </div>

  
      <div  className='relative lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] h-96 mt-44'>
      <img className='absolute lg:w-[100%] lg:h-[146%] md:w-[100%]  md:h-[146%] sm:w-[100%] sm:h-[160%] xs:w-[100%] xs:h-[160%] mt-0' src={industryImg} alt='industry Image' />


        <div className='relative lg:w-[100%] lg:h-[146%] md:w-[100%] md:h-[146%] sm:h-[160%] xs:h-[160%] bg-gray-900  opacity-95 lg:flex lg:flex-row md:flex md:flex-row sm:flex sm:flex-col xs:flex xs:flex-col' >
        <div className='absolute lg:w-[50%] md:w-[50%] sm:w-[70%] xs:w-[80%] lg:mt-10 md:mt-10 sm:mt-10 xs:mt-10 sm:pl-28 xs:pl-14 sm:ml-8 xs:ml-4'>
            <img className='lg:w-[80%] md:w-[80%] lg:pl-2 md:pl-2  lg:pt-40 md:pt-40 sm:pt-4 xs:pt-4' src={logoImage} alt='logo' />    
        </div>
          


        <div className='relative text-gray-400 text-2xl lg:w-[50%] md:w-[50%] sm:w-[70%] xs:w-[70%] sm:mx-auto  font-normal lg:pt-8 md:pt-8 sm:pt-52 xs:pt-52 lg:pl-72 md:pl-48 sm:pl-0 xs:pl-4'>Contact Us
        
        <div className='absolute  pt-4 w-[100%]'>
        
            
            <div className=' absolute lg:text-base md:text-base sm:text-base xs:text-sm lg:pl-8 md:pl-8 sm:pl-4 xs:pl-0 tracking-wider'>
            <div className=' xs:w-80'>
            <div className='flex flex-row'>
            <div>{locationIcon}</div>
            <div className='lg:text-lg md:text-base sm:text-base xs:text-md pl-2'>BLR SAFETY SOLUTIONS PRIVATE LIMITED
            <div className='lg:text-base'>       
            No:44/1802 THEJUS AUDITORIUM <br/>
            FACING MARGIN FREE SHOP NORTH SIDE,<br/>
            PALARIVATTOM, ERNAKULAM<br/>
            Land Mark: OPP.TRINITY GOLD <br/>
            PALARIVATTOM,THAMMANAM ROAD<br/>
            PALARIVATTOM JUNCTION<br/>
            KERALA-682025, INDIA. 
            </div> 
            
            </div>
            </div> 
            

            <div className='w-56 h-0.5 bg-gray-600 lg:mt-0 md:mt-0 sm:mt-0 xs:mt-0 ml-8'></div>
                  <div className='relative'>
                    <div className='absolute pt-3'>{mailIcon2}</div>
                    <div className='absolute pl-8 pt-3 text-base tracking-wider'>info@blrsafetysolutions.com</div>
                  </div>
                  <div className='w-56 h-0.5 bg-gray-600 mt-12 ml-8'></div>

                  <div className="relative">
                        <div className="absolute pt-4">{phoneIcon}</div>
                        <div className="pl-8 pt-3 text-lg tracking-wider">
                          <a href="tel:+917510617107" className="hover:underline">+91 75106 17107</a>,
                        </div>
                        <div className="pl-8 text-lg  tracking-wider">
                          <a href="tel:+918590119100" className="hover:underline">+91 85901 19100</a>
                        </div>
                      </div>
                 {/* <div className='relative'>
                    <div className='absolute pt-4'>{phoneIcon}</div>
                    <div className='absolute pl-8 pt-3 text-lg tracking-wider'>+91 75106 17107</div>
                    <div className='absolute pl-8  text-lg pt-9 tracking-wider'>+91 85901 19100</div>
                  </div>*/}
            </div>

            </div>  


          
                    

        </div>
       
      
        </div> 

        
        
      </div>


    </div>
    
    <div className='w-[100%] h-24 bg-black text-gray-700 lg:pt-4 md:pt-0 sm:mt-44 xs:mt-48 lg:pl-4 md:pl-4 text-small tracking-wide'>BLR Safety Solutions Pvt.Ltd.</div>
 
    </div>
    </>
  )
}

export default About